import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/kernel-v2/kernel-v2/src/modules/layouts/default_layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "cheat-sheet",
      "style": {
        "position": "relative"
      }
    }}>{`Cheat Sheet`}<a parentName="h1" {...{
        "href": "#cheat-sheet",
        "aria-label": "cheat sheet permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h1>
    <p>{`Use the table below whenever you'd like to add something visual to your text. The `}<strong parentName="p">{`Syntax`}</strong>{` column is what should help you most, as it contains everything you can do as a writer for this site. The `}<strong parentName="p">{`Props`}</strong>{` column gives you additional information about how each component can be extended with specific properties you may need.`}</p>
    <h2 {...{
      "id": "overview-of-all-components",
      "style": {
        "position": "relative"
      }
    }}>{`Overview of all components`}<a parentName="h2" {...{
        "href": "#overview-of-all-components",
        "aria-label": "overview of all components permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Component`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Syntax`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Markdown Equivalent`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Props`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Link`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`<Link />`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`[Text](https://link)`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`to`}</inlineCode>{`: the URL `}<br /><inlineCode parentName="td">{`icon`}</inlineCode>{`: an icon from DAI-ui `}<br /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Button`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`<Button />`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`to`}</inlineCode>{`: the URL `}<br />{` `}<inlineCode parentName="td">{`variant`}</inlineCode>{`: A property from dai-ui `}<br />{` `}<inlineCode parentName="td">{`secondary`}</inlineCode>{`: use secondary styles `}<br />{` `}<inlineCode parentName="td">{`outline`}</inlineCode>{`: use outline styles. `}<strong parentName="td">{`Can be combined with secondary`}</strong>{` `}<br />{` `}<inlineCode parentName="td">{`disabled`}</inlineCode>{`: Disable button interaction `}<br />{` `}<inlineCode parentName="td">{`inline`}</inlineCode>{`: Force the button to be 'display: inline-block' `}<strong parentName="td">{`block by default`}</strong>{` `}<br />{` `}<inlineCode parentName="td">{`sx`}</inlineCode>{`: SX style props`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Inline Code`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`\``}{`\``}{`\``}{`js:title=example.js`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`\``}{`\``}{`\``}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Just remember that you can add titles and language highlighting!`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`CTA`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`<CTA />`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`>`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Callout`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`<Callout />`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`secondary`}</inlineCode>{`: use secondary styles, `}<strong parentName="td">{`defaults to primary`}</strong>{` `}<br />{` `}<inlineCode parentName="td">{`icon`}</inlineCode>{`: specify the icon to appear with text`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Status Banner`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`<StatusBanner />`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`warning`}</inlineCode>{`: orange `}<br />{` `}<inlineCode parentName="td">{`error`}</inlineCode>{`: red `}<br />{` `}<inlineCode parentName="td">{`notice`}</inlineCode>{`: yellow `}<br />{` `}<inlineCode parentName="td">{`color`}</inlineCode>{`: custom color i.e. #fff000 or "red" `}<br />{` `}<inlineCode parentName="td">{`sticky`}</inlineCode>{`: make the element use it's sticky version`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Image`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`<Image />`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`![Alt text](image link)`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None - use Aligner for styling`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Aligner`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`<Aligner />`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`center`}</inlineCode>{`: Center the image on the X and Y `}<br />{` `}<inlineCode parentName="td">{`right`}</inlineCode>{`: Align the image to the right `}<br />{` `}<inlineCode parentName="td">{`bottom`}</inlineCode>{`: Align the image to the bottom `}<br />{` `}<inlineCode parentName="td">{`yCenter`}</inlineCode>{`: Center the image on the Y axis `}<br />{` `}<inlineCode parentName="td">{`xCenter`}</inlineCode>{`: Center the image on the X axis`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Inline Video`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`\``}{`youtube: mzDVaKRApcg`}{`\``}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Supports YouTube (as shown), Vimeo, videoPress.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Playlist Video`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`<Video />`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`src`}</inlineCode>{`: expects an `}<strong parentName="td">{`iframe embed url`}</strong>{` beginning with `}<strong parentName="td">{`videoseries?list=`}</strong>{` `}<br />{` `}<inlineCode parentName="td">{`title`}</inlineCode>{`: provide an accessibility title for your iframe. Defaults to "Inline Iframe Video".`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Checklist`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`<Checklist />`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No props - just follow the Child Rule and you can put anything in here!`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`List`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`<List />`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`-`}</inlineCode>{` or `}<inlineCode parentName="td">{`*`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No props`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Accordion`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`<Accordion />`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`openIcon`}</inlineCode>{`: defaults to `}<inlineCode parentName="td">{`+`}</inlineCode>{`, can be anything from DAI-ui though `}<br />{` `}<inlineCode parentName="td">{`closeIcon`}</inlineCode>{`: defaults to `}<inlineCode parentName="td">{`-`}</inlineCode>{` `}<br />{` `}<inlineCode parentName="td">{`defaultOpen`}</inlineCode>{`: control whether the menu is open, or closed (default)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Process Stepper`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`<Process />`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No props`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Column`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`<Column />`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No props`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Box`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`<Box />`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No props, but this is how we bundle together lots of children (i.e. new lines in mdx) and pass them as one thing to React components. Works like a `}<inlineCode parentName="td">{`<div>`}</inlineCode>{` in html. See `}<a parentName="td" {...{
              "href": "https://theme-ui.com/components/box"
            }}>{`here`}</a>{` for more info and uses.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Chocolate Box`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`<Chocolate />`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`iconSize`}</inlineCode>{`: applied by default if the first child of Chocolate "Box" is an Icon (52px default) `}<br />{` `}<inlineCode parentName="td">{`minBoxSize`}</inlineCode>{`: Minimum Size of each grid cell (for responsiveness) (100px default) `}<br />{` `}<inlineCode parentName="td">{`gapSize`}</inlineCode>{`: Size of gaps between grid cells (58px default)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Tout`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`<Tout />`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`alt`}</inlineCode>{`: applies yellow alt color `}<br />{` `}<inlineCode parentName="td">{`color`}</inlineCode>{`: specify custom css colors/hex codes for the background. If a custom color prop is provided the copy will change to check against HexLuminance rules. `}<br />{` `}<inlineCode parentName="td">{`stroke`}</inlineCode>{`: removes background and applies a colored border of (default, alt, or color) `}<br />{` `}<inlineCode parentName="td">{`fade`}</inlineCode>{`: fades the background from the specified color (or default/alt variants) to transparent. `}<br />{` `}<inlineCode parentName="td">{`image`}</inlineCode>{`: applies 1 of 2 static images depending on the existence of the alt prop.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`InfoBlock`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`<InfoBlock />`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`None`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No props, but three kinds. `}<br />{` 1. Image on left (put `}<inlineCode parentName="td">{`<Image />`}</inlineCode>{` as first child) `}<br />{` 2. Image on right (put `}<inlineCode parentName="td">{`<Image />`}</inlineCode>{` as last child) `}<br />{` 3. No image priority (`}<inlineCode parentName="td">{`<Image />`}</inlineCode>{` neither first nor last).`}</td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "using-the-sx-property",
      "style": {
        "position": "relative"
      }
    }}>{`Using the "sx" property`}<a parentName="h2" {...{
        "href": "#using-the-sx-property",
        "aria-label": "using the sx property permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p><a parentName="p" {...{
        "href": "https://theme-ui.com/home"
      }}>{`Theme UI`}</a>{` is a library which allows us to do cool things
like building a fully themed design system for an open source, decentralized community of makers. Enter `}<a parentName="p" {...{
        "href": "https://design-system.mkr-js-prod.now.sh/"
      }}>{`DAI-UI`}</a>{`. Built on top of Theme UI, DAI-UI provides us with predefined spacings, fontsizes, colors, icons, and much, much more.
Moreover, we can be sure that all our colours, icons, spacing, headers and whatever other styles we wish to use are all the same not just on this website, but across `}<strong parentName="p">{`every single community project`}</strong>{`! It's really mind-blowing when you get to grips with it.`}</p>
    <p>{`You, as the content creator, have `}<strong parentName="p">{`complete control`}</strong>{` over how the content appears, and you need never ask a developer to insert some fancy UI element for you. It's all within your power...`}</p>
    <p>{`You can provide any css style you want in the `}<inlineCode parentName="p">{`sx`}</inlineCode>{` prop. It's best to consult the docs above - especially the "Recipes" files of DAI-UI - to get a sense of the great variety on offer. Here, we'll just explain the preset sizing and spaces like the `}<inlineCode parentName="p">{`4`}</inlineCode>{` we've used above and what they mean, so that you can use the correct preset in the correct place and we can achieve uniformity across our entire ecosystem.`}</p>
    <p>{`If you'd like to set up mobile styles, just pass an array of values to the `}<inlineCode parentName="p">{`sx`}</inlineCode>{` prop, beginning with mobile, then tablet, then desktop. So, if I'd like different padding for different devices for my component, I could write `}<inlineCode parentName="p">{`sx={{ padding:'[1, 2, 3]' }}`}</inlineCode>{`. The exact breakpoints are defined `}<a parentName="p" {...{
        "href": "https://github.com/kernel-community/kernel-v2/blob/r2d/src/gatsby-plugin-theme-ui/index.js#L44"
      }}>{`here`}</a>{`.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Note that `}<inlineCode parentName="p">{`sx`}</inlineCode>{` props must use camelCase, which means first letter lower case, any other word uppercase and no spacing.
So the css property font-size becomes fontSize, or background-image becomes backgroundImage when being passed as a prop to
any React component. To find more details on object styling, please see the `}<a parentName="p" {...{
          "href": "https://theme-ui.com/guides/object-styles/"
        }}>{`Theme UI docs`}</a>{`.`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`The `}<inlineCode parentName="p">{`fontSize`}</inlineCode>{` and `}<inlineCode parentName="p">{`Spacing`}</inlineCode>{` are given below in `}<strong parentName="p">{`px`}</strong>{`. `}<inlineCode parentName="p">{`fontSize`}</inlineCode>{` can be used for any text element, including headings, and
`}<inlineCode parentName="p">{`spacing`}</inlineCode>{` gets applied both when specifying margins or padding.`}</p>
    </blockquote>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Preset`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`fontSize`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Spacing`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`10`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`2`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`12`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`4`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`3`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`14`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`8`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`4`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`16`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`16`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`5`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`18`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`32`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`6`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`20`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`64`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`7`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`24`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`128`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`8`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`32`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`256`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`9`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`48`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`512`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`10`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`64`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`11`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`96`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      